import React from 'react'
import { Link } from 'gatsby'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Image from '../../components/image'
import { Row, Col, ProductBookCard, Button, Video } from '../../components/global'
import config from '../../siteConfig'

const H115 = () => (
  <Layout>
    <SEO title="The Relay Troubleshooting Trainer, H-115" />
    <div className="container container-last">
      <h1>
        The Relay
        <br />
        Troubleshooting Trainer, H-115
      </h1>
      <p className="boost">
        Learn how relays are used in vehicle circuits, how they fail and the troubleshooting
        techniques to fix them, once and for all.
      </p>
      <hr />
      <Row>
        <Col width="30%" align="left">
          <ProductBookCard
            title="The Relay Troubleshooting Trainer, H-115"
            titleSmall={true}
            step="Hands-on Kit"
            animate={false}
            price={209.0}
          />
        </Col>
        <Col width="68%" align="left">
          <p className="demote">
            <strong>
              Before purchasing this product, make sure you own{' '}
              <Link to="/products/h-111a">The Starter Kit H-111A</Link> as it is required to own
              this kit.
            </strong>
          </p>
          <p>
            Troubleshooting Relays can be a difficult task given all the possibilities of how a
            relay circuit can fail.
          </p>
          <p>
            What do you do after replacing a relay and it does not fix the relay circuit problem?{' '}
            <strong>Let us show you!</strong>
          </p>
          <p>
            <Button
              href="/products/circuit-board-trainer-inventory-clearance"
              text="See Purchasing Options"
              type="secondary"
            />
          </p>
        </Col>
      </Row>
      <hr />
      <p className="boost">See The Relay Troubleshooting Trainer, H-115 in action.</p>
      <Video
        title="See The Relay Troubleshooting Trainer, H-115 in action"
        src="https://www.youtube.com/embed/1WhsU0SW-k0"
      />
      <hr />
      <p>
        This program contains extensive step-by-step instruction and hands-on exercises. Techs learn
        to analyze the ways relay circuits fail, how to measure voltage, voltage drops, resistance
        and current in relay circuits and to identify relay circuit problems.
      </p>
      <p>
        Tech also learn how spike suppression diodes connected across a relay coil work and what
        happens when the diode fails.
      </p>
      <p>
        Lastly spike suppression diode testing with an analog ohmmeter, a digital ohmmeter and the
        Diode Test feature of a DMM is discussed with hands-on exercises to follow along
        step-by-step.
      </p>
      <Row>
        <Col width="49%" align="left">
          <p>Troubleshooting Relay Circuits Module H-115 contains the following:</p>
          <ul>
            <li>1 - Relay Circuit Board. H-PCB05</li>
            <li>1 - Resistor Bag H-RB05</li>
            <li>1 - Home-Study Student Man. H-WB115</li>
            <li>1 - Instructor Guide H-IG115</li>
          </ul>
        </Col>
        <Col width="49%" align="left">
          <Image file="h-115.jpg" />
        </Col>
      </Row>
      <hr />
      <h2>What Next?</h2>
      <p>
        Continue developing your troubleshooting skills and learning more about auto/truck
        electrical/electronic troubleshooting with the following live circuit troubleshooting
        trainers!
      </p>
      <ul>
        <li>
          <Link to="/products/h-116">Wire Harness Troubleshooting Trainer H-116</Link>
        </li>
        <li>
          <Link to="/products/h-200">CAN Bus Troubleshooting Trainer H-200(S)</Link>
        </li>
      </ul>
    </div>
  </Layout>
)

export default H115
